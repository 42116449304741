/***************************************************************************************************
 * GLOBAL CONFIGURATIONS FOR NODE.JS-LIKE ENVIRONMENT
 */
(window as any).global = window;

// Import Buffer and configure it globally
import { Buffer } from 'buffer';
(window as any).Buffer = Buffer;

// Configure global process for compatibility
global.process = {
    env: { DEBUG: undefined },
    version: '',
    nextTick: require('next-tick')
} as any;

import '@angular/localize/init';
import 'zone.js'; // Included with Angular CLI

